import React, { useMemo } from "react"
import { get } from "lodash"
import { useWindowSizes } from "@hooks/window"
import { linkResolver } from "../../utils/linkResolver"
import { RichText } from "prismic-reactjs"
import { LazyLoadImage } from "react-lazy-load-image-component"

const HeroLeadLargeImage = ({ data, index }) => {
  const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  // console.log('[HeroLeadLargeImage.js] data', data)

  const title = useMemo(() => get(data, "primary.title[0].text"), [data])

  const icon = useMemo(() => get(data, "primary.icon"), [data])

  const button_link = useMemo(() => get(data, "primary.button_link._meta"), [
    data,
  ])

  const buttonUrl =
    button_link !== "" && button_link !== null ? linkResolver(button_link) : ""

  const button_text = useMemo(() => get(data, "primary.button_text[0].text"), [
    data,
  ])

  const colorMode = useMemo(() => get(data, "primary.color_mode"), [data])

  const colorModeClass = colorMode === true ? " dark-mode" : ""

  const label = useMemo(() => get(data, "primary.label"), [data])

  const image = useMemo(() => get(data, "primary.image"), [data])

  const logos = useMemo(() => get(data, "fields"), [data])

  // Image size
  let imgWidth = 1152
  isIphonePlus
    ? (imgWidth = 366)
    : isIpad
    ? (imgWidth = 704)
    : isIpadPro
    ? (imgWidth = 615)
    : isDesktop
    ? (imgWidth = 1152)
    : (imgWidth = 1152)

  return (
    <section
      key={index}
      className={"hero_lead hero_lead-big_image hero_lead-rev" + colorModeClass}
    >
      <div className="row row-justify">
        <div className="col col6 hero_lead__left_col">
          <div className="hero_lead__heading">
            <img src={icon?.url} alt={icon?.alt ?? ""} />
            <h1 className="special-font">{title}</h1>
            <div className="hero_lead__heading__label label-l">
              <RichText render={label} />
            </div>
            {button_text ? (
              <a href={buttonUrl} className="btn-secondary white w-arrow">
                {button_text}
              </a>
            ) : null}

            <div className="hero_lead__logos">
              {logos &&
                logos?.map((item, index) => (
                  <img
                    key={index}
                    src={item?.logo?.url}
                    alt={item?.logo?.alt ?? title}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="col col6 hero_lead__right_col beans">
          {/* <img src={image?.url} alt={image?.alt ?? title} /> */}
          <LazyLoadImage
            threshold={1000}
            effect="blur"
            srcSet={`
                ${image?.url}&ar=2:1&fit=auto&dpr=1&w=${imgWidth} 1x,
                ${image?.url}&ar=2:1&fit=auto&dpr=2&w=${imgWidth} 2x,
                ${image?.url}&ar=2:1&fit=auto&dpr=3&w=${imgWidth} 3x,
                ${image?.url}&ar=2:1&fit=auto&dpr=4&w=${imgWidth} 4x
              `}
            src={image?.url}
            alt={image?.alt ?? title}
            placeholderSrc={`${image?.url}&ar=2:1&fit=auto&dpr=1&w=10`}
            width="1156"
            height="1440"
          />
        </div>
      </div>
    </section>
  )
}

export default HeroLeadLargeImage
