import React, { useMemo } from 'react'
import { get } from 'lodash'
import { RichText } from 'prismic-reactjs'
import HubspotForm from 'react-hubspot-form'

const NewsletterFrom = ({ data, index }) => {

  console.log('[NewsletterFrom.js] data', data) 

  const title = useMemo(() => (
    get(data, 'primary.title[0].text')
  ), [data])

  const text = useMemo(() => (
    get(data, 'primary.text')
  ), [data])

  const formId = useMemo(() => (
    get(data, 'primary.form_id')
  ), [data])

  const portalId = useMemo(() => (
    get(data, 'primary.portal_id')
  ), [data])

  const colorMode = useMemo(() => (
    get(data, 'primary.color_mode')
  ), [data])

  const colorModeClass = colorMode === true ? ' dark-mode' : ''

  return(
    <section
      key={index}
      className={"newsletter_form"+colorModeClass}>
      <div className="row row-centered">
        <div className="col col6">
          <h2>
            {title}
          </h2>
          <div className="p-large font-book">
            <RichText
              render={text}
            />
          </div>

          {portalId && formId ? 
            <div className="newsletter_form__hubspostform">
              <HubspotForm
                portalId={portalId}
                formId={formId}
                onSubmit={() => {}}
                onReady={(form) => {}}
                // onLoad={loadHubspotCSS()}
                // loading={<div className="loader"></div>}
                // ref={hubspotForm}
                // loading={<div className="loader"><img src={loader} alt="loader" /></div>}
              />
            </div>
          : null}

          {/* <form className="newsletter_form__form">
            <input type="email" required={true} placeholder="Enter Your Email Address" />
            <button className="btn-secondary white w-arrow">
              <span>Sign-up</span>
            </button>
          </form> */}
        </div>
      </div>
    </section>
  ) 
}

export default NewsletterFrom
