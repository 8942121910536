import React, { useMemo, useState } from 'react'
import { get } from 'lodash'
// import { useWindowSizes } from '@hooks/window'
import { linkResolver } from '../../utils/linkResolver'
import { RichText } from 'prismic-reactjs'
import InfoCard from './InfoCard'
import ScrollContainer from 'react-indiana-drag-scroll'
// import info_arrow from '../../images/info_arrow.svg'
// import SvgArrow from '../../svgs/info_arrow.svg'
// import SvgArrow from './../../svgs/info_arrow.svg'

// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import 'swiper/swiper.scss'
// import 'swiper/components/navigation/navigation.scss'
// import 'swiper/components/pagination/pagination.scss'

// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);


const HeroLeadCards = ({ data, index }) => {

  // const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  const [activeCardIndex, setActitiveCardIndex] = useState(0);

  console.log('[HeroLeadCards.js] data', data)

  const title = useMemo(() => (
    get(data, 'primary.title[0].text')
  ), [data])

  const icon = useMemo(() => (
    get(data, 'primary.icon')
  ), [data])

  const button_link = useMemo(() => (
    get(data, 'primary.button_link._meta')
  ), [data])

  const buttonUrl = button_link !== '' && button_link !== null ? linkResolver(button_link) : ''

  const button_text = useMemo(() => (
    get(data, 'primary.button_text[0].text')
  ), [data])

  const colorMode = useMemo(() => (
    get(data, 'primary.color_mode')
  ), [data])

  const colorModeClass = colorMode === true ? ' dark-mode' : ''

  const label = useMemo(() => (
    get(data, 'primary.label')
  ), [data])

  const cards = useMemo(() => (
    get(data, 'fields')
  ), [data])

  console.log(cards)

  const setActiveCard = (index) => {
    setActitiveCardIndex(index)
  }

  return(
    <section
      key={index}
      className={"hero_lead_info_cards hero_lead-image"+colorModeClass}>
        <div className="row row-justify">
          <div className="col col6 hero_lead_info_cards__left_col">
            <div className="hero_lead_info_cards__heading">
              <img src={icon?.url} alt={icon?.alt ?? ""} />
              <div className="hero_lead_info_cards__heading__label label-l">
                <RichText
                  render={label}
                />
              </div>
              <h1 className="special-font">
                {title}
              </h1>
              {button_text ? 
                <a href={buttonUrl} className="btn-secondary white w-arrow">
                  {button_text}
                </a>
              : null}
            </div>
          </div>
          <div className="col col1 hidden_ipadPro"></div>
          <div className="col col5 hero_lead_info_cards__right_col">
            <div className="hero_lead_info_cards__right_content">
              <div className="hero_lead_info_cards__information_cards_head">
                <ul>
                  
                  {cards && cards.map((card, index) => (
                    <li key={index} onClick={() => setActiveCard(index)} onMouseOver={() => setActiveCard(index)} className={activeCardIndex === index ? "active": ""}>
                      <label>
                        {card?.card_name && card?.card_name[0]?.text}
                      </label>
                      <span></span>
                      <div className="info_cards__path"></div>
                      {/* <img src={SvgArrow} /> */}
                    </li>
                  ))}
                </ul>
              </div>
              
            
            
              <ScrollContainer
                className="scroll-container"
                hideScrollbars={false}>
                <div className="card_slider__container__swiper path">
                  {cards.filter((card, index) => index === activeCardIndex).map((card, index) => (
                    <InfoCard key={index} data={card} />
                  ))}
                </div>
              </ScrollContainer>

              {/* <Swiper
                spaceBetween={0}
                slidesPerView={1}
                navigation
                loop={true}
                pagination={{ "dynamicBullets": true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
              >
                <SwiperSlide>
                  <img src="https://picsum.photos/seed/1/400/550" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="https://picsum.photos/seed/2/400/250" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="https://picsum.photos/seed/3/400/250" />
                </SwiperSlide> 
             
              </Swiper> */}
            </div>

            
           
          </div>
        </div>
    </section>
  )  

}

export default HeroLeadCards
