import React, { useMemo } from "react"
import { get } from "lodash"
// import { useWindowSizes } from '@hooks/window'
import { linkResolver } from "../../utils/linkResolver"
import { Strings } from "../../utils/strings"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useWindowMeasures } from "@hooks/window"

const FamilyCard = ({ data, index, colorMode }) => {
  // console.log('[FamilyCard.js] data', data)

  const title = useMemo(() => get(data, "title[0].text"), [data])

  const illustration_couple = useMemo(
    () => get(data, "illustration_couple_big"),
    [data]
  )

  const illustration_couple_light = useMemo(
    () => get(data, "illustration_couple_light_big"),
    [data]
  )

  const description = useMemo(() => get(data, "description[0].text"), [data])

  const meta = useMemo(() => get(data, "_meta"), [data])

  const allStrings = Strings(meta)
  // console.log('[renderCard] allStrings', allStrings)

  const linkUrl = linkResolver(meta)

  return (
    <a key={index} href={linkUrl} className="family_card">
      {/* <img 
        className="family_card__img_dark"
        src={illustration_couple?.url}
        alt={illustration_couple?.alt ?? title} /> */}
      <LazyLoadImage
        threshold={1000}
        effect="blur"
        className="family_card__img_dark"
        src={illustration_couple?.url}
        alt={illustration_couple?.alt ?? title}
        width="744"
        height="355"
      />

      {colorMode === true ? (
        // <img
        //   className="family_card__img_light"
        //   src={illustration_couple_light?.url}
        //   alt={illustration_couple_light?.alt ?? title} />
        <LazyLoadImage
          threshold={1000}
          effect="blur"
          className="family_card__img_light"
          src={illustration_couple_light?.url}
          alt={illustration_couple_light?.alt ?? title}
          width="744"
          height="355"
        />
      ) : null}

      <h2 className="family_card__title special-font">{title}</h2>

      <p className="p-medium font-book family_card__info">{description}</p>

      <button className="family_card__button btn-secondary white small w-arrow">
        {allStrings?.cards_family_card_button ?? "Explore Family"}
      </button>
    </a>
  )
}

export default FamilyCard
