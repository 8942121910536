import React, { useMemo } from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import HomeHeader from "../components/slices/HomeHeader"
import WideImage from "../components/slices/WideImage"
import HeroLead from "../components/slices/HeroLead"
import EditorialTextImage from "../components/slices/EditorialTextImage"
import SecondaryHeader from "../components/slices/SecondaryHeader"
import LeadAndText from "../components/slices/LeadAndText"
import PeopleCards from "../components/slices/PeopleCards"
import IlustrationEditorialBanner from "../components/slices/IlustrationEditorialBanner"
import EditorialTextImageContact from "../components/slices/EditorialTextImageContact"
import HeroBean from "../components/slices/HeroBean"
import HeroLeadLargeImage from "../components/slices/HeroLeadLargeImage"
import HeroLeadCountry from "../components/slices/HeroLeadCountry"
import NewsletterFrom from "../components/slices/NewsletterFrom"
import CardsSlider from "../components/slices/CardsSlider"
import HeroLeadCards from "../components/slices/HeroLeadCards"

// Display the title, date, and content of the Post
const HomeBody = ({ page }) => {
  console.log("[home.js] page", page)

  const renderContainer = (container, index) => {
    if (container && container.type === "text___image") {
      return <EditorialTextImage key={index} data={container} index={index} />
    }

    if (container && container.type === "secondary_header") {
      return <SecondaryHeader key={index} data={container} index={index} />
    }

    if (container && container.type === "lead___cards") {
      return <LeadAndText key={index} data={container} index={index} />
    }

    if (container && container.type === "the_team") {
      return <PeopleCards key={index} data={container} index={index} />
    }

    if (container && container.type === "illustration_banner") {
      return (
        <IlustrationEditorialBanner
          key={index}
          data={container}
          index={index}
        />
      )
    }

    if (container && container.type === "contact_us") {
      return (
        <EditorialTextImageContact key={index} data={container} index={index} />
      )
    }

    if (container && container.type === "card_slider") {
      return <CardsSlider key={index} data={container} index={index} />
    }

    if (container && container.type === "home_header") {
      return <HomeHeader key={index} data={container} index={index} />
    }

    if (container && container.type === "wide_image") {
      return <WideImage key={index} data={container} index={index} />
    }

    if (container && container.type === "lead___text") {
      return <HeroLead key={index} data={container} index={index} type="text" />
    }

    if (container && container.type === "lead___text_1") {
      return (
        <HeroLead key={index} data={container} index={index} type="image" />
      )
    }

    if (container && container.type === "lead___text_2") {
      return <HeroLeadLargeImage key={index} data={container} index={index} />
    }

    if (container && container.type === "lead_country") {
      return <HeroLeadCountry key={index} data={container} index={index} />
    }

    if (container && container.type === "newsletter_form") {
      return <NewsletterFrom key={index} data={container} index={index} />
    }

    if (container && container.type === "coffee_bean") {
      return <HeroBean key={index} data={container} index={index} />
    }

    if (container && container.type === "lead___text_3") {
      return <HeroLeadCards key={index} data={container} index={index} />
    }

    return []
  }

  if (!page) return null

  return (
    <section>
      <div className="containerGlobal">
        {page &&
          page?.body?.map((container, index) =>
            renderContainer(container, index)
          )}
      </div>
    </section>
  )
}

export default props => {
  if (!props) return null

  const doc = props.data.prismic.allHomes.edges[0]

  if (!doc) return null

  const meta = doc.node._meta

  const seoTitle = useMemo(() => get(doc, "node.seo_title[0].text"), [doc])

  const seoText = useMemo(() => get(doc, "node.seo_meta[0].text"), [doc])

  const seoImage = useMemo(() => get(doc, "node.seo_image.url"), [doc])

  return (
    <Layout pathname={props.path} meta={meta} isHome={true}>
      <SEO
        title={seoTitle ? seoTitle : "Transparent, Sustainable and Direct"}
        description={seoText}
        image={seoImage}
      />
      <HomeBody page={doc.node} />
    </Layout>
  )
}

export const query = graphql`
  query homeQuery($lang: String) {
    prismic {
      allHomes(lang: $lang, uid: "home") {
        edges {
          node {
            _meta {
              lang
              uid
              type
              alternateLanguages {
                lang
                uid
                type
              }
            }
            seo_title
            seo_meta
            seo_image
            body {
              ... on PRISMIC_HomeBodyCard_slider {
                type
                label
                primary {
                  color_mode
                  layout
                  title
                }
                fields {
                  card {
                    ... on PRISMIC_Coffee_family {
                      title
                      description
                      illustration_couple
                      illustration_individual
                      illustration_outline_couple
                      illustration_couple_light
                      illustration_individual_light
                      illustration_couple_light_big
                      illustration_couple_big
                      _meta {
                        uid
                        lang
                        type
                      }
                    }
                    ... on PRISMIC_Fazenda {
                      _meta {
                        uid
                        lang
                        type
                      }
                      title
                      main_image
                      intro
                      local_location
                      country {
                        ... on PRISMIC_Country {
                          title
                          flag
                          _meta {
                            uid
                            lang
                          }
                        }
                      }
                    }
                    ... on PRISMIC_Product {
                      _meta {
                        uid
                        lang
                        type
                      }
                      family {
                        ... on PRISMIC_Coffee_family {
                          title
                          description
                          illustration_individual
                          _meta {
                            uid
                            lang
                          }
                        }
                      }
                      title
                      sku
                      notes {
                        note
                      }
                      # harvest
                      # current_location
                      # bags_available
                      main_image
                      fazenda {
                        ... on PRISMIC_Fazenda {
                          title
                          _linkType
                          country {
                            ... on PRISMIC_Country {
                              title
                              flag
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomeBodyHome_header {
                type
                label
                primary {
                  color_mode
                  logo
                  text
                  title
                  big_image
                }
              }
              ... on PRISMIC_HomeBodyWide_image {
                type
                label
                primary {
                  image
                }
              }
              ... on PRISMIC_HomeBodyLead___text {
                type
                label
                primary {
                  button_link {
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
                  title
                  icon
                  content
                  color_mode
                  button_text
                }
              }
              ... on PRISMIC_HomeBodyCoffee_bean {
                type
                label
                primary {
                  color_mode
                }
              }
              ... on PRISMIC_HomeBodyLead___text_1 {
                type
                label
                primary {
                  button_link {
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                  }
                  button_text
                  color_mode
                  icon
                  image
                  label
                  title
                }
              }
              ... on PRISMIC_HomeBodyLead___text_2 {
                type
                label
                primary {
                  title
                  label
                  icon
                  image
                  color_mode
                  button_text
                  button_link {
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
                fields {
                  logo
                }
              }
              ... on PRISMIC_HomeBodyLead_country {
                type
                label
                primary {
                  button_link {
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                  }
                  title
                  text
                  label
                  image
                  color_mode
                  button_text
                }
                fields {
                  icon
                }
              }
              ... on PRISMIC_HomeBodyNewsletter_form {
                type
                label
                primary {
                  text
                  title
                  color_mode
                  portal_id
                  form_id
                }
              }
              ... on PRISMIC_HomeBodyLead___text_3 {
                type
                label
                primary {
                  button_link {
                    ... on PRISMIC__ExternalLink {
                      target
                      _linkType
                      url
                    }
                    ... on PRISMIC__Document {
                      _meta {
                        lang
                        type
                        uid
                      }
                    }
                  }
                  button_text
                  color_mode
                  icon
                  title
                  label
                }
                fields {
                  card_image
                  card_name
                  card_text
                }
              }
            }
          }
        }
      }
    }
  }
`
