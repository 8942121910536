import React, { useMemo } from 'react'
import { get } from 'lodash'
// import { useWindowSizes } from '@hooks/window'
import { linkResolver } from '../../utils/linkResolver'
import { RichText } from 'prismic-reactjs'

const HeroLead = ({ data, index, type }) => {

  // const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  // console.log('[HeroLead.js] data', data)

  const title = useMemo(() => (
    get(data, 'primary.title[0].text')
  ), [data])

  const icon = useMemo(() => (
    get(data, 'primary.icon')
  ), [data])

  const button_link = useMemo(() => (
    get(data, 'primary.button_link._meta')
  ), [data])

  const buttonUrl = button_link !== '' && button_link !== null ? linkResolver(button_link) : ''

  const button_text = useMemo(() => (
    get(data, 'primary.button_text[0].text')
  ), [data])

  const content = useMemo(() => (
    get(data, 'primary.content')
  ), [data])

  const colorMode = useMemo(() => (
    get(data, 'primary.color_mode')
  ), [data])

  const colorModeClass = colorMode === true ? ' dark-mode' : ''

  // image type

  const label = useMemo(() => (
    get(data, 'primary.label')
  ), [data])

  const image = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

   
  if(type === "text") {
    return(
      <section
        key={index}
        className={"hero_lead"+colorModeClass}>
          <div className="row row-justify">
            <div className="col col6 hero_lead__left_col">
              <div className="hero_lead__heading">
                <img 
                  src={icon?.url} 
                  alt={icon?.alt ?? ""} 
                  loading="lazy"
                  width="195"
                  height="195hero_lead__heading"
                />
                <h1 className="special-font">
                  {title}
                </h1>
                {button_text ? 
                  <a href={buttonUrl} className="btn-secondary white w-arrow">
                    {button_text}
                  </a>
                : null}
              </div>
            </div>

            <div className="col col5 hero_lead__right_col font-book">
              <RichText render={content} linkResolver={linkResolver} />
            </div>
          </div>
      </section>
    )  
  } else if (type === "image") {
    return(
      <section
        key={index}
        className={"hero_lead hero_lead-image"+colorModeClass}>
          <div className="row row-justify">
            <div className="col col6 hero_lead__left_col">
              <div className="hero_lead__heading">
                <img src={icon?.url} alt={icon?.alt ?? ""} />
                <div className="hero_lead__heading__label label-l">
                  <RichText
                    render={label}
                  />
                </div>
                <h1 className="special-font">
                  {title}
                </h1>
                {button_text ? 
                  <a href={buttonUrl} className="btn-secondary white w-arrow">
                    {button_text}
                  </a>
                : null}
              </div>
            </div>
            <div className="col col6 hero_lead__right_col">
         
              <img src={image?.url} alt={image?.alt ?? title} />
            </div>
          </div>
      </section>
    )  
  }
}

export default HeroLead
