import React, { useMemo } from "react"
import { get } from "lodash"
import { useWindowSizes } from "@hooks/window"
import { LazyLoadImage } from "react-lazy-load-image-component"
// import 'react-lazy-load-image-component/src/effects/blur.css'

const WideImage = ({ data, index }) => {
  const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()
  // const { height } = useWindowMeasures()
  // const wideImageRef = useRef()
  // const [wideImageOffsetTop, setWideImageOffsetTop] = useState(0)
  // const [imgHeight, setImgHeight] = useState()

  const image = useMemo(() => get(data, "primary.image"), [data])

  // Image size
  let imgWidth = 1920
  isIphonePlus
    ? (imgWidth = 414)
    : isIpad
    ? (imgWidth = 768)
    : isIpadPro
    ? (imgWidth = 1024)
    : isDesktop
    ? (imgWidth = 1440)
    : (imgWidth = 1920)

  // let wideImageOffsetTop
  // let windowHeight

  // console.log('imgHeight', imgHeight)

  // TODO NOT WORKING WELL

  // useLayoutEffect(() => {
  //   const updateUnits = () => {
  //     // setWideImageOffsetTop(wideImageRef.current.offsetTop)
  //     wideImageOffsetTop = wideImageRef.current.offsetTop
  //     windowHeight =  window.innerHeight
  //     setImgHeight(windowHeight - wideImageOffsetTop)

  //     console.log('wideImageOffsetTop', wideImageOffsetTop, 'height', windowHeight)
  //   }
  //   window.addEventListener('resize', updateUnits)
  //   updateUnits()

  //   return () => window.removeEventListener('resize', updateUnits)
  // }, [])

  return (
    <section
      key={index}
      className={"wide_image"}
      // ref={wideImageRef}
      // style={{height: imgHeight}}
    >
      <LazyLoadImage
        threshold={1000}
        effect="blur"
        alt={image?.alt ?? "Ocafi"}
        srcSet={`
          ${image?.url}&fit=crop&dpr=1&w=${imgWidth} 1x,
          ${image?.url}&fit=crop&dpr=2&w=${imgWidth} 2x,
          ${image?.url}&fit=crop&dpr=3&w=${imgWidth} 3x,
          ${image?.url}&fit=crop&dpr=4&w=${imgWidth} 4x
        `}
        src={image?.url}
        placeholderSrc={`${image?.url}&fit=crop&dpr=1&w=10`}
        width="1920"
        height="515"
      />
    </section>
  )
}

export default WideImage
