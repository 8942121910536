import React, { useMemo } from "react"
import { get } from "lodash"
// import ReactPlayer from 'react-player'
// import LazyLoad from 'react-lazyload'
// import { RichText } from 'prismic-reactjs'
// import { linkResolver } from '@prismicio/gatsby-source-prismic-graphql'
// import '../../../node_modules/uikit/dist/js/uikit.js'
// import { useWindowSizes } from '@hooks/window'
// import { Waypoint } from 'react-waypoint'
// import LogoImg from '../../images/logo.svg'
import { RichText } from "prismic-reactjs"
import { useWindowSizes } from "@hooks/window"

const HomeHeader = ({ data, index }) => {
  // console.log('[HomeHeader.js] data', data)
  const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  const logo = useMemo(() => get(data, "primary.logo"), [data])

  const title = useMemo(() => get(data, "primary.title"), [data])

  const text = useMemo(() => get(data, "primary.text"), [data])

  const colorMode = useMemo(() => get(data, "primary.color_mode", false), [
    data,
  ])

  const image = useMemo(() => get(data, "primary.big_image"), [data])

  // Image size
  let imgWidth = 1920
  isIphonePlus
    ? (imgWidth = 414)
    : isIpad
    ? (imgWidth = 768)
    : isIpadPro
    ? (imgWidth = 1024)
    : isDesktop
    ? (imgWidth = 1440)
    : (imgWidth = 1920)

  const colorModeClass = colorMode === true ? " dark-mode" : ""

  return (
    <section key={index} className={"home_header" + colorModeClass}>
      <div className="row">
        <div className="col col6 home_header__col home_header__col-logo">
          <img src={logo?.url} alt={logo?.alt ?? "Ocafi"} />
        </div>
        <div className="col col1"></div>
        <div className="col col4 home_header__col home_header__col-text">
          <RichText render={title} />

          <div className="p-large font-book">
            <RichText render={text} />
          </div>
        </div>
        <div className="col col1 hidden_ipadPro"></div>
      </div>

      <div className="home_header__image">
        <img
          threshold={1000}
          alt={image?.alt ?? "Ocafi"}
          srcSet={`
            ${image?.url}&fit=crop&dpr=1&w=${imgWidth} 1x,
            ${image?.url}&fit=crop&dpr=2&w=${imgWidth} 2x,
            ${image?.url}&fit=crop&dpr=3&w=${imgWidth} 3x,
            ${image?.url}&fit=crop&dpr=4&w=${imgWidth} 4x
          `}
          src={image?.url}
          width="1920"
          height="515"
        />
      </div>
    </section>
  )
}

export default HomeHeader
