import React, { useMemo } from "react"
import { get } from "lodash"
import { useWindowSizes } from "@hooks/window"
// import BeanImg from '../../images/angles.png'
import { LazyLoadImage } from "react-lazy-load-image-component"

const HeroBean = ({ data, index }) => {
  const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  // console.log('[HeroBean.js] data', data)

  const colorMode = useMemo(() => get(data, "primary.color_mode"), [data])

  const colorModeClass = colorMode === true ? " dark-mode" : ""

  const BeanImgUrl =
    "https://images.prismic.io/ocafi/e68c454d-022d-449b-8e54-0a15174c5c50_angles-7cffb5007b71b1115ab17fcdc9e68078.png?auto=compress,format"

  // Image size
  let imgWidth = 608
  isIphonePlus
    ? (imgWidth = 259)
    : isIpad
    ? (imgWidth = 235)
    : isIpadPro
    ? (imgWidth = 320)
    : isDesktop
    ? (imgWidth = 608)
    : (imgWidth = 608)

  return (
    <section
      key={index}
      className={"hero_bean hidden_iphonePlus" + colorModeClass}
    >
      <img
        className="hero_bean__bg hero_bean__bg-light"
        src="https://ocafi.cdn.prismic.io/ocafi/aebe3f0e-a69a-4dae-ac26-d408430c241d_big_illustration_bean.svg"
        alt=""
      />

      <img
        className="hero_bean__bg hero_bean__bg-dark"
        src="https://ocafi.cdn.prismic.io/ocafi/0c159dc8-c097-45c9-81af-dcf398f5bee9_bean_illus_dark.svg"
        alt=""
      />

      <div className="row row-centered hero_bean__bean">
        <div className="col col4">
          {/* <img src={BeanImg} alt="Ocafi" /> */}
          <LazyLoadImage
            threshold={1000}
            effect="blur"
            alt=""
            srcSet={`
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=1&w=${imgWidth} 1x,
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=2&w=${imgWidth} 2x,
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=3&w=${imgWidth} 3x,
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=4&w=${imgWidth} 4x
            `}
            src={BeanImgUrl}
            placeholderSrc={`${BeanImgUrl}&ar=2:1&fit=auto&dpr=1&w=10`}
            width="608"
            height="930"
          />
          {/* <img 
            srcSet={`
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=1&w=${imgWidth} 1x,
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=2&w=${imgWidth} 2x,
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=3&w=${imgWidth} 3x,
              ${BeanImgUrl}&ar=2:1&fit=auto&dpr=4&w=${imgWidth} 4x
            `}
            src={BeanImgUrl}
            alt={'Ocafi'}
          /> */}
        </div>
      </div>
    </section>
  )
}

export default HeroBean
