import React, { useMemo } from 'react'
import { get } from 'lodash'
// import { useWindowSizes } from '@hooks/window'
// import { Swiper, SwiperSlide } from 'swiper'
// import { Swiper, SwiperSlide, Mousewheel } from 'swiper/react'
import 'swiper/swiper.scss'
import FamilyCard from './FamilyCard'
import FazendaCard from './FazendaCard'
import ScrollContainer from 'react-indiana-drag-scroll'
import { RichText } from 'prismic-reactjs'


const CardsSlider = ({ data, index, page }) => {

  // const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  // console.log('[CardsSlider.js] data', data)

  
  const cards = useMemo(() => (
    get(data, 'fields')
  ), [data])

  const colorMode = useMemo(() => (
    get(data, 'primary.color_mode', false)
  ), [data])

  const title = useMemo(() => (
    get(data, 'primary.title', false)
  ), [data])

  const sliderLayout = useMemo(() => (
    get(data, 'primary.layout')
  ), [data])

  const sliderLayoutClass = sliderLayout === 'Fazenda Cards' ? ' fazenda_cards' : '';
  const scrollContainerRowClass = sliderLayout === 'Fazenda Cards' ? '' : ' row';

  // console.log('sliderLayout', sliderLayout)

  const colorModeClass = colorMode === true ? ' dark-mode' : ''

  const renderCard = (card, index) => {

    // console.log('renderCard card', card)

    if (card && card._meta.type === "coffee_family") {
      return (<FamilyCard key={index} data={card} index={index} colorMode={colorMode} />)
    }

    if (card && card._meta.type === "fazenda") {
      return (<FazendaCard key={index} item={card} index={index} colorMode={colorMode} />)
    }
  
    return []
  }
   
  return(
    <section
      key={index}
      className={"card_slider"+colorModeClass+sliderLayoutClass}>
       
        {title &&
          <div className="row">
            <div className="col col12">
              <RichText
                render={title}
              />
            </div>
          </div>
        }
        
        <ScrollContainer
          className={"scroll-container"+scrollContainerRowClass}
          hideScrollbars={false}>
          <div className="card_slider__container">
            {cards && cards.map((item, index) => (
              renderCard(item?.card, index)
            ))}
          </div>
        </ScrollContainer>
    </section>
  )  
}

export default CardsSlider
