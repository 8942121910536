import React, { useMemo } from 'react'
import { get } from 'lodash'
// import { useWindowSizes } from '@hooks/window'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const HeroLeadCountry = ({ data, index}) => {

  // const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()
   
  // console.log('[HeroLeadCountry.js] data', data)

  const title = useMemo(() => (
    get(data, 'primary.title[0].text')
  ), [data])

  // const icon = useMemo(() => (
  //   get(data, 'primary.icon')
  // ), [data])

  const button_link = useMemo(() => (
    get(data, 'primary.button_link._meta')
  ), [data])

  const buttonUrl = button_link !== '' && button_link != null ? linkResolver(button_link) : ''

  const button_text = useMemo(() => (
    get(data, 'primary.button_text[0].text')
  ), [data])

  const text = useMemo(() => (
    get(data, 'primary.text')
  ), [data])

  const colorMode = useMemo(() => (
    get(data, 'primary.color_mode')
  ), [data])

  const colorModeClass = colorMode === true ? ' dark-mode' : ''

  const label = useMemo(() => (
    get(data, 'primary.label')
  ), [data])

  const image = useMemo(() => (
    get(data, 'primary.image')
  ), [data])

  const icons = useMemo(() => (
    get(data, 'fields')
  ), [data])

  return(
    <section
      key={index}
      className={"hero_lead_country"+colorModeClass}>
        <div className="row row-justify">
          <div className="col col5 hero_lead_country__left_col">
            <div className="hero_lead_country__heading">
              <div className="hero_lead_country__heading__label label-l">
                <RichText
                  render={label}
                />
              </div>
              <h1 className="special-font">
                {title}
              </h1>
              {button_text ? 
                <a href={buttonUrl} className="btn-secondary white w-arrow">
                  {button_text}
                </a>
              : null}
            </div>
          </div>
        </div>

        <img 
          className="hero_lead_country__illustration" 
          src="https://ocafi.cdn.prismic.io/ocafi/c9e71537-383f-4a27-a5c8-3bc2324f2d6e_illustration_overlay.svg"
          alt="Ocafi Illustration"
        />
        
        <img 
          className="hero_lead_country__map" 
          src={image?.url} 
          alt={image?.alt ?? title} 
          width="1195"
          height="964"
        />

        <div className="row">
          <div className="col col5 p-large font-book">
          
            <div className="hero_lead_country__icons">
              {icons && icons?.map((item, index) => (
                <img
                  key={index}
                  src={item?.icon?.url}
                  alt={item?.icon?.alt ?? title} />
              ))}
            </div>

            {text ? 
              <RichText
                render={text}
              />
            : null}

          </div>
        </div>
    </section>
  )   
}

export default HeroLeadCountry
