import React, { useMemo } from 'react'
import { get } from 'lodash'
// import { useWindowSizes } from '@hooks/window'
// import { linkResolver } from '../../utils/linkResolver'
// import { Strings } from '../../utils/strings'
import { RichText } from 'prismic-reactjs'
import Image from './Image'

const InfoCard = ({ data, index }) => {

  
  
  console.log('[InfoCard.js] data', data)

  const image = useMemo(() => (
    get(data, 'card_image')
  ), [data])

  const card_text = useMemo(() => (
    get(data, 'card_text')
  ), [data])

  return(
    <div 
      className={"info_card"} 
      key={index}>        

      <div className="info_card__text p-large font-book">
        <RichText 
          render={card_text}
        />
      </div>

      <div className="info_card__cover">
        {/* <img src={image?.url} alt={image?.alt} /> */}
        <Image image={image} width={594} />
      </div>
      
    </div>
  )  
}

export default InfoCard
