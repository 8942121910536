import React from "react"
import { get } from "lodash"
import { useWindowSizes } from "@hooks/window"
import { linkResolver } from "../../utils/linkResolver"
import { Strings } from "../../utils/strings"
import { LazyLoadImage } from "react-lazy-load-image-component"

const FazendaCard = ({ item, index }) => {
  const { isIpadPro, isIpad, isIphonePlus, isDesktop } = useWindowSizes()

  // console.log('[FazendaCard.js] item', item)

  const title = get(item, "title[0].text", "Title placeholder")
  const main_image = get(item, "main_image")
  const intro = get(item, "intro[0].text")
  const meta = get(item, "_meta")
  const linkUrl = linkResolver(meta)
  const country_title = get(item, "country.title[0].text", "Country name")
  const country_flag = get(item, "country.flag")
  const local_location = get(item, "local_location[0].text")

  const allStrings = Strings(meta)
  // console.log('[renderCard] product',product )

  // Image size
  let imgWidth = 698
  isIphonePlus
    ? (imgWidth = 293)
    : isIpad
    ? (imgWidth = 266)
    : isIpadPro
    ? (imgWidth = 350)
    : isDesktop
    ? (imgWidth = 698)
    : (imgWidth = 698)

  // const { width } = useWindowMeasures()
  // let margin
  // if(width > 1440 && index === 0) {
  //   margin = (width - 1440) / 2 + 48
  // }

  return (
    <div className="product_card product_card-fazenda" key={index}>
      <a href={linkUrl}>
        <div className="product_card__top">
          <div className="product_card__top__info">
            <div className="country_tag">
              <img
                src={country_flag?.url}
                alt={country_flag?.alt ?? country_title}
              />
              {local_location ? local_location : country_title}
            </div>
          </div>
        </div>

        <div className="product_card__cover">
          {/* {local_location ? 
            <div className="product_card__cover__tag tag">
              {local_location}
            </div>
          : null } */}
          {/* <img src={main_image?.url} alt={main_image?.alt ?? title} /> */}
          <LazyLoadImage
            threshold={1000}
            effect="blur"
            srcSet={`
              ${main_image?.url}&ar=2:1&fit=auto&dpr=1&w=${imgWidth} 1x,
              ${main_image?.url}&ar=2:1&fit=auto&dpr=2&w=${imgWidth} 2x,
              ${main_image?.url}&ar=2:1&fit=auto&dpr=3&w=${imgWidth} 3x,
              ${main_image?.url}&ar=2:1&fit=auto&dpr=4&w=${imgWidth} 4x
            `}
            src={main_image?.url}
            alt={main_image?.alt ?? title}
            placeholderSrc={`${main_image?.url}&ar=2:1&fit=auto&dpr=1&w=10`}
          />
        </div>

        <div className="product_card__main_info">
          <h2 className="product_card__main_info__title h3">{title}</h2>
          <p className="p-medium font-book product_card__intro">{intro}</p>
        </div>

        <button
          href={linkUrl}
          className="product_card__button btn-secondary white small w-arrow"
        >
          {allStrings?.cards_fazenda_card_button ?? "More info"}
        </button>
      </a>
    </div>
  )
}

export default FazendaCard
